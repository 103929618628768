import React from "react";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";
// import Img from "gatsby-image";
import Layout from "../components/layout";
// import SEO from "../components/seo";
import Tobics from "../components/tobics-side-bar";
import Featured_posts from "../components/featured_posts";
import SinglePost from "../components/post";
/* eslint react/prop-types: 0 */
export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      limit: $limit
      skip: $skip
      filter: { node_locale: { eq: "en-US" } }
    ) {
      nodes {
        title
        excerpt {
          excerpt
        }
        slug
        author {
          profilePicture {
            fixed(width: 60, quality: 100, height: 60) {
              width
              height
              src
              srcSet
            }
          }
          name
        }
        createdAt(formatString: "MM/DD/YYYY")
        featuredImage {
          fluid(maxWidth: 1600) {
            ...GatsbyContentfulFluid
          }
        }
        category {
          slug
          title
        }
        content {
          raw
          references {
            description
            fluid(maxWidth: 750) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;

const Blog = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? "/blog#posts"
      : `${(currentPage - 1).toString()}#posts`;
  const nextPage = `${(currentPage + 1).toString()}#posts`;
  return (
    <Layout>
      <SEO
        keywords={[`Hostylus`, `Hostylus Blog`, `Blog`]}
        title="Hostylus Blog"
        lang="en"
        image={"/hostylus-card-preview.jpg"}
      />
      <Featured_posts />
      <section className="flex mb-32 gap-4 mobile:block tablet:block mobile:m-6 tablet:m-8 ">
        <Tobics />
        <div>
          <ul className="posts grid grid-cols-3  mx-auto my-8 laptop:mx-0 tablet:w-full mobile:w-full mobile:grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-2">
            {data.allContentfulBlogPost.nodes.map((item) => (
              <SinglePost
                item={item}
                key={Math.random()}
                category={item.category[0]}
                postImage={item.featuredImage}
                title={item.title}
                excerpt={item.excerpt.excerpt}
                createdAt={item.createdAt}
                author={item.author}
                slug={item.slug}
              />
            ))}
            {
              //data.allContentfulBlogPost.nodes.map((item)=>(
              //    <SEO title="Blog" key={Math.random()} keywords={item.title} />
              // ))
            }
          </ul>
          <div className="grid grid-cols-3 max-w-5xl mx-auto my-8 laptop:mx-6 tablet:w-full mobile:w-full">
            <div className={"text-left"}>
              {!isFirst && (
                <Link
                  to={prevPage}
                  rel="prev"
                  className={
                    "mobile:text-xs outline-none bg-blue-600 py-2 tablet:text-xs font-bold text-white mobile:mr-0  rounded-3xl px-8 mobile:px-2.5 border-2 mb-2 mt-8 tablet:mt-4 mobile:mt-4 uppercase"
                  }
                >
                  ← Prev Page
                </Link>
              )}
            </div>

            <div className={"text-center"}>
              {Array.from({ length: numPages }, (_, i) =>
                numPages == 1 ? (
                  ""
                ) : (
                  <Link
                    className={"mx-2 font-semibold text-md hover:text-blue-600"}
                    key={`pagination-number${i + 1}`}
                    to={`/blog/${i === 0 ? "" : i + 1}#posts`}
                  >
                    {i + 1}
                  </Link>
                )
              )}
            </div>
            <div className={"text-right"}>
              {!isLast && (
                <Link
                  to={nextPage}
                  rel="next"
                  className={
                    "mobile:text-xs outline-none bg-blue-600 py-2 tablet:text-xs font-bold text-white mobile:mr-0  rounded-3xl px-8 mobile:px-2.5 border-2 mb-2 mt-8 tablet:mt-4 mobile:mt-4 uppercase"
                  }
                >
                  Next Page →
                </Link>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Blog;
